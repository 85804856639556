<template>
  <div
    id="col-left"
    class="o-layout__item u-display--block u-display--inline-block@md u-12/12 u-3/12@md col-left--box"
  >
    <form
      id="j_id_69"
      name="j_id_69"
      method="post"
      action="/accommodation/onlyAccommodationAvail.xhtml?tripId=2&amp;availPosition=1"
      onkeypress="return event.keyCode !== 13;"
      enctype="application/x-www-form-urlencoded"
      data-partialsubmit="true"
    >
      <div
        id="j_id_69:comp-summary"
        class="ongoing hidden-print comp-summary only-hotel"
      >
        <nav
          class="float-breadcrumb navbar o-position o-position--above o-position--fixed o-position--top u-1/1"
          role="navigation"
          id="navegacion"
          style="z-index: 1001; display: none"
        >
          <header class="nav-brochure">
            <div
              id="j_id_69:j_id_6b:breadcrumbs-availability"
              class="wrapperNavbar breadcrumbs-availability"
            ></div>
          </header>
        </nav>
        <div
          class="c-fast-confirm mb-10 u-display--none u-display--block@md true"
        >
          <div class="c-fast-confirm__hero c-fast-confirm__hero--destination">
            <div class="c-fast-confirm__hero__wrapper-img">
              <img
                data-src="https://cdn5.travelconline.com/unsafe/fit-in/0x200/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2Fnorthamerica%2Fflorida%2Ffloridakeys%2Fthumbnail.jpg"
                src="https://cdn5.travelconline.com/unsafe/fit-in/0x200/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2Fnorthamerica%2Fflorida%2Ffloridakeys%2Fthumbnail.jpg"
                data-index=""
                data-error-src="https://booking.vacancyrewards.com/javax.faces.resource/images/no-photo-XS.jpg"
                onerror="this.setAttribute('data-original-url', this.src); var errorUrl = this.getAttribute('data-error-src'); if(this.src !== errorUrl) {this.src = errorUrl};"
                loading="lazy"
                alt="Florida Keys FL"
                width=""
                height=""
                style=""
                class="lazy-img c-fast-confirm__hero__img loaded"
                data-ll-status="loaded"
              />
            </div>
            <div class="c-fast-confirm__hero__body u-text-align--center">
              <span>Accommodation in</span>
              <b
                class="u-display--block u-font-size--big c-fast-confirm__hero__title"
              >
                {{ city }}, {{ country }}
              </b>
            </div>
          </div>
        </div>
        <div class="c-travel-details u-display--none u-display--block@md">
          <div
            class="c-travel-details__container u-display--flex@sm u-display--block@md u-gap--small"
          >
            <!-- <div
              class="c-travel-details__item c-travel-detail c-travel-details__item--people o-layout__item u-1/2@sm u-1/1@md"
            >
              <div class="c-travel-detail__icon">
                <i class="fa-regular fa-users"></i>
              </div>
              <div class="c-travel-detail__content">2 Adults</div>
            </div> -->

            <div
              class="c-travel-details__item c-travel-detail c-travel-details__item--dates o-layout__item u-1/2@sm u-1/1@md"
            >
              <div class="c-travel-detail__icon">
                <i class="fa-regular fa-calendar-lines"></i>
              </div>
              <div
                class="c-travel-detail__content trip-departure-arrival-dates"
              >
                {{ formatDate(checkIn) }} - {{ formatDate(checkOut) }}
              </div>
            </div>

            <!-- Filtros -->
            <br />
            <div class="c-sb-input__addicon c-sb-input__addicon--drop">
              <select
                size="1"
                class="c-sb-input__field dev-select-country"
                @change="filterType"
                v-model="checkIn"
              >
                <!-- <option :value="null">{{ $func.parseIso("weekType") }}</option> -->
                <!-- <option :value="null" v-if="!checkInSelected">
                  CheckIn: {{ formatDate(checkIn) }}
                </option> -->
                <option :value="item" v-for="item in checkInList">
                  CheckIn: {{ formatDate(item) }}
                </option>
              </select>
              <i class="c-sb-input__icon fa-light fa-calendar-check"></i>
            </div>
            <br />
            <div class="c-sb-input__addicon c-sb-input__addicon--drop">
              <select
                size="1"
                class="c-sb-input__field dev-select-country"
                @change="filterType"
                v-model="checkOut"
              >
                <!-- <option :value="null">{{ $func.parseIso("weekType") }}</option> -->
                <!-- <option :value="null" v-if="!checkOutSelected">
                  CheckOut: {{ formatDate(checkOut) }}
                </option> -->
                <option :value="item" v-for="item in checkOutList">
                  CheckOut: {{ formatDate(item) }}
                </option>
              </select>
              <i class="c-sb-input__icon fa-light fa-calendar-times"></i>
            </div>
            <br />
            <div class="c-sb-input__addicon c-sb-input__addicon--drop">
              <select
                size="1"
                class="c-sb-input__field dev-select-country"
                @change="filterType"
                v-model="type"
              >
                <!-- <option :value="null">{{ $func.parseIso("weekType") }}</option> -->
                <option :value="null">
                  {{
                    !type ? $func.parseIso("weekType") : $func.parseIso("all")
                  }}
                </option>
                <option value="Last Minute Week">Last Minute Week</option>
                <option value="Smart Week">Smart Week</option>
                <option value="VIP Week">VIP Week</option>
              </select>
              <i class="c-sb-input__icon fa-light fa-calendar"></i>
            </div>
            <br />
            <div class="c-sb-input__addicon c-sb-input__addicon--drop">
              <select
                size="1"
                class="c-sb-input__field dev-select-country"
                @change="filterType"
                v-model="capacity"
              >
                <option :value="null">
                  {{
                    !capacity
                      ? $func.parseIso("capacity")
                      : $func.parseIso("all")
                  }}
                </option>
                <option value="1 BEDROOM">1 BEDROOM</option>
                <option value="2 BEDROOM">2 BEDROOM</option>
                <option value="3 BEDROOM">3 BEDROOM</option>
                <option value="HOTEL">HOTEL</option>
                <option value="STUDIO">STUDIO</option>
              </select>
              <i class="c-sb-input__icon fa-light fa-users"></i>
            </div>
            <br />
            <div class="c-sb-input__addicon c-sb-input__addicon--drop">
              <select
                size="1"
                class="c-sb-input__field dev-select-country"
                @change="filterType"
                v-model="kitchen"
              >
                <option :value="null">
                  {{
                    !kitchen ? $func.parseIso("kitchen") : $func.parseIso("all")
                  }}
                </option>
                <option value="Full Kitchen">Full Kitchen</option>
                <option value="No kitchen">No Kitchen</option>
                <option value="Partial Kitchen">Partial Kitchen</option>
              </select>
              <i class="c-sb-input__icon fa-light fa-burger-soda"></i>
            </div>
            <br />
            <div class="c-sb-input__addicon c-sb-input__addicon--drop">
              <select
                size="1"
                class="c-sb-input__field dev-select-country"
                @change="filterType"
                v-model="occupancy"
              >
                <option :value="null">
                  {{
                    !occupancy
                      ? $func.parseIso("occupancy")
                      : $func.parseIso("all")
                  }}
                </option>
                <option :value="null">{{ $func.parseIso("all") }}</option>
                <option :value="2">2</option>
                <option :value="4">4</option>
                <option :value="6">6</option>
                <option :value="8">8</option>
              </select>
              <i class="c-sb-input__icon fa-light fa-users-line"></i>
            </div>
            <br />
            <div class="c-sb-input__addicon c-sb-input__addicon--drop">
              <select
                size="1"
                class="c-sb-input__field dev-select-country"
                @change="filterType"
                v-model="resort"
              >
                <option :value="null">
                  {{ $func.parseIso("resortSelect") }}
                </option>
                <!-- <option :value="null">{{ $func.parseIso("all") }}</option> -->
                <option :value="resort" v-for="resort in resorts">
                  {{ resort }}
                </option>
              </select>
              <i class="c-sb-input__icon fa-light fa-hotel"></i>
            </div>

            <br />
            <div class="c-sb-input__addicon">
              <a
                id="j_id_54:init-compositor-all:search"
                name="j_id_54:init-compositor-all:search"
                class="c-sb-input__button c-button continuar bg-clr--brand u-1/1 call-pp-help dev-holidays-search-btn"
                title="Buscar"
                data-placement="bottom"
                data-content="Y busque paquetes"
                @click="resetFilters"
              >
                {{ $func.parseIso("resetFilters") }}
              </a>
            </div>

            <!-- Fin Filtros -->

            <!-- <div
              class="c-travel-details__item c-travel-detail c-travel-details__item--nights u-display--none u-display--flex@md"
            >
              <div class="c-travel-detail__icon">
                <i class="fa-regular fa-moon"></i>
              </div>
              <div class="c-travel-detail__content">
                7 Nights
                <span class="trip-nights"> 7 </span>
              </div>
            </div> -->
          </div>
          <!-- <a
            data-target="#composeModal"
            data-toggle="modal"
            id="openComposeModalButton"
            href="#"
            class="c-travel-details__item c-button c-button--block c-button--solid--default c-travel-details__button edit-trip-button"
          >
            Change your trip
          </a> -->
        </div>
        <div id="c-hidden-aside__bg" class="c-hidden-aside__bg"></div>
        <div
          id="c-hidden-aside"
          :class="`c-hidden-aside c-hidden-aside@md modifier pt-0 ${
            (openFilter && 'c-hidden-aside--opened') || ''
          }`"
        >
          <div
            class="c-sticky-header u-zindex--128 u-display--none@md o-block__item o-position--top o-position--sticky"
          >
            <div class="o-flexbox o-flexbox--generic-row">
              <div
                class="o-flexbox o-flexbox--generic-row u-align-items--center"
              >
                <a
                  class="c-sticky-header__button ux-close-filters"
                  @click="showOpenFilters"
                  ><i class="fa-regular fa-arrow-left"></i
                ></a>
                <div class="u-line-height--1 u-ellipsis-inline">
                  <b> {{ $func.parseIso("back") }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="o-block c-hidden-aside__scroller">
            <div
              id="j_id_69:j_id_6b:j_id_us:side-summary-aside"
              class="side-summary-aside o-block__item"
            >
              <ul
                class="u-font-size--small o-flexbox o-flexbox--generic-row nav nav-tabs mb-20 u-display--none@md"
                role="tablist"
              >
                <li role="presentation" class="u-flex--11 active">
                  <a
                    class="o-group--tiny c-tab-button"
                    id="button-tab-filters"
                    aria-controls="tab-filters"
                    role="tab"
                    data-toggle="tab"
                    ><i class="fa-regular fa-sliders-h"></i
                    ><span>{{ $func.parseIso("filters") }}</span></a
                  >
                </li>
                <!-- <li role="presentation" class="u-flex--11 u-display--none@md">
                  <a
                    class="o-group--tiny c-tab-button button-tab-sort"
                    id="button-tab-sort"
                    href="#tab-sort"
                    aria-controls="tab-sort"
                    role="tab"
                    data-toggle="tab"
                    ><i class="fa-regular fa-sort-amount-down"></i
                    ><span>Sort</span></a
                  >
                </li> -->
              </ul>
              <div class="tab-content mb-20">
                <div
                  class="tab-pane active"
                  role="tabpanel"
                  id="tab-filters"
                  v-if="openFilter"
                >
                  <div
                    id="j_id_69:j_id_6b:j_id_us:side-filters"
                    class="side-filters"
                  >
                    <div class="">
                      <div class="o-block">
                        <div class="o-block__item o-block o-block--middle">
                          <div
                            class="o-block o-block--small o-block__item"
                          ></div>
                          <div class="o-block__item o-block o-block--middle">
                            <div class="o-block o-block--small o-block__item">
                              <div
                                class="c-sb-input__addicon c-sb-input__addicon--drop"
                              >
                                <select
                                  size="1"
                                  class="c-sb-input__field dev-select-country"
                                  @change="filterType"
                                  v-model="checkIn"
                                >
                                  <option
                                    :value="item"
                                    v-for="item in checkInList"
                                  >
                                    CheckIn: {{ formatDate(item) }}
                                  </option>
                                </select>
                                <i
                                  class="c-sb-input__icon fa-light fa-calendar-check"
                                ></i>
                              </div>
                              <br />
                              <div
                                class="c-sb-input__addicon c-sb-input__addicon--drop"
                              >
                                <select
                                  size="1"
                                  class="c-sb-input__field dev-select-country"
                                  @change="filterType"
                                  v-model="checkOut"
                                >
                                  <option
                                    :value="item"
                                    v-for="item in checkOutList"
                                  >
                                    CheckOut: {{ formatDate(item) }}
                                  </option>
                                </select>
                                <i
                                  class="c-sb-input__icon fa-light fa-calendar-times"
                                ></i>
                              </div>
                              <br />
                              <div
                                class="c-sb-input__addicon c-sb-input__addicon--drop"
                              >
                                <select
                                  size="1"
                                  class="c-sb-input__field dev-select-country"
                                  @change="filterType"
                                  v-model="type"
                                >
                                  <!-- <option :value="null">{{ $func.parseIso("weekType") }}</option> -->
                                  <option :value="null">
                                    {{
                                      !type
                                        ? $func.parseIso("weekType")
                                        : $func.parseIso("all")
                                    }}
                                  </option>
                                  <option value="Last Minute Week">
                                    Last Minute Week
                                  </option>
                                  <option value="Smart Week">Smart Week</option>
                                  <option value="VIP Week">VIP Week</option>
                                </select>
                                <i
                                  class="c-sb-input__icon fa-light fa-calendar"
                                ></i>
                              </div>
                              <br />
                              <div
                                class="c-sb-input__addicon c-sb-input__addicon--drop"
                              >
                                <select
                                  size="1"
                                  class="c-sb-input__field dev-select-country"
                                  @change="filterType"
                                  v-model="capacity"
                                >
                                  <option :value="null">
                                    {{
                                      !capacity
                                        ? $func.parseIso("capacity")
                                        : $func.parseIso("all")
                                    }}
                                  </option>
                                  <option value="1 BEDROOM">1 BEDROOM</option>
                                  <option value="2 BEDROOM">2 BEDROOM</option>
                                  <option value="3 BEDROOM">3 BEDROOM</option>
                                  <option value="HOTEL">HOTEL</option>
                                  <option value="STUDIO">STUDIO</option>
                                </select>
                                <i
                                  class="c-sb-input__icon fa-light fa-hotel"
                                ></i>
                              </div>
                              <br />
                              <div
                                class="c-sb-input__addicon c-sb-input__addicon--drop"
                              >
                                <select
                                  size="1"
                                  class="c-sb-input__field dev-select-country"
                                  @change="filterType"
                                  v-model="kitchen"
                                >
                                  <option :value="null">
                                    {{
                                      !kitchen
                                        ? $func.parseIso("kitchen")
                                        : $func.parseIso("all")
                                    }}
                                  </option>
                                  <option value="Full Kitchen">
                                    Full Kitchen
                                  </option>
                                  <option value="No kitchen">No Kitchen</option>
                                  <option value="Partial Kitchen">
                                    Partial Kitchen
                                  </option>
                                </select>
                                <i
                                  class="c-sb-input__icon fa-light fa-burger-soda"
                                ></i>
                              </div>
                              <br />
                              <div
                                class="c-sb-input__addicon c-sb-input__addicon--drop"
                              >
                                <select
                                  size="1"
                                  class="c-sb-input__field dev-select-country"
                                  @change="filterType"
                                  v-model="occupancy"
                                >
                                  <option :value="null">
                                    {{
                                      !occupancy
                                        ? $func.parseIso("occupancy")
                                        : $func.parseIso("all")
                                    }}
                                  </option>
                                  <option :value="null">
                                    {{ $func.parseIso("all") }}
                                  </option>
                                  <option :value="2">2</option>
                                  <option :value="4">4</option>
                                  <option :value="6">6</option>
                                  <option :value="8">8</option>
                                </select>
                                <i
                                  class="c-sb-input__icon fa-light fa-users-line"
                                ></i>
                              </div>
                              <br />
                              <div
                                class="c-sb-input__addicon c-sb-input__addicon--drop"
                              >
                                <select
                                  size="1"
                                  class="c-sb-input__field dev-select-country"
                                  @change="filterType"
                                  v-model="resort"
                                >
                                  <option :value="null">
                                    {{ $func.parseIso("resortSelect") }}
                                  </option>
                                  <!-- <option :value="null">{{ $func.parseIso("all") }}</option> -->
                                  <option
                                    :value="resort"
                                    v-for="resort in resorts"
                                  >
                                    {{ resort }}
                                  </option>
                                </select>
                                <i
                                  class="c-sb-input__icon fa-light fa-hotel"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div
                            id="j_id_69:j_id_6b:j_id_us:j_id_1c2:holidayPackagePreferredAccomodationsFiltersPanel"
                            class="o-block__item"
                          ></div>
                          <div
                            id="j_id_69:j_id_6b:j_id_us:j_id_1c2:popularFiltersPanel"
                            class="dev-popular-filters o-block__item"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  role="tabpanel"
                  id="tab-sort"
                  class="tab-pane u-display--none@md"
                >
                  <div
                    id="j_id_69:j_id_6b:j_id_us:aside-sort"
                    class="sort-selector o-block o-block--small u-line-height--1"
                  >
                    <div class="" aria-labelledby="sortHotelsButton">
                      <div class="o-block o-block--small">
                        <div class="o-block__item">
                          <a
                            id="j_id_69:j_id_6b:j_id_us:j_id_2h6:sortByTravelc"
                            href="#"
                            class="ui-commandlink ui-widget u-display--block o-box--tiny--vertical u-padding--null@sm"
                            aria-label=""
                          >
                            <span class="u-font-weight--bold">
                              Our selection
                            </span></a
                          >
                        </div>
                        <div class="o-block__item">
                          <a
                            id="j_id_69:j_id_6b:j_id_us:j_id_2h6:sortByPopularity"
                            href="#"
                            class="ui-commandlink ui-widget u-display--block o-box--tiny--vertical u-padding--null@sm sortByPopularity"
                            aria-label=""
                          >
                            <span class=""> Most popular </span></a
                          >
                        </div>
                        <div class="o-block__item">
                          <a
                            id="j_id_69:j_id_6b:j_id_us:j_id_2h6:sortByDistance"
                            href="#"
                            class="ui-commandlink ui-widget u-display--block o-box--tiny--vertical u-padding--null@sm sortByDistance"
                            aria-label=""
                          >
                            <span class=""> Distance to center </span></a
                          >
                        </div>
                        <div class="o-block__item">
                          <a
                            id="j_id_69:j_id_6b:j_id_us:j_id_2h6:sortByPrice"
                            href="#"
                            class="ui-commandlink ui-widget u-display--block o-box--tiny--vertical u-padding--null@sm"
                            aria-label=""
                          >
                            <span class=""> Price (lowest first) </span></a
                          >
                        </div>
                        <div class="o-block__item">
                          <a
                            id="j_id_69:j_id_6b:j_id_us:j_id_2h6:j_id_2hn"
                            href="#"
                            class="ui-commandlink ui-widget u-display--block o-box--tiny--vertical u-padding--null@sm"
                            aria-label=""
                          >
                            <span class=""> Stars (lowest first) </span></a
                          >
                        </div>
                        <div class="o-block__item">
                          <a
                            id="j_id_69:j_id_6b:j_id_us:j_id_2h6:j_id_2hq"
                            href="#"
                            class="ui-commandlink ui-widget u-display--block o-box--tiny--vertical u-padding--null@sm"
                            aria-label=""
                          >
                            <span class=""> Stars (highest first) </span></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="u-display--none@md">
            <div class="o-box--small bg-clr--white">
              <a
                class="c-button c-button--solid--default bg-clr--brand u-1/1"
                @click="resetFilters"
                ><span>{{ $func.parseIso("resetFilters") }}</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { setSearchWeekObject, getSearchWeekObject } from "../../utils/sesions";

export default {
  props: ["country", "city", "state", "checkOut", "checkIn", "token"],
  data() {
    return {
      type: null,
      occupancy: null,
      capacity: null,
      kitchen: null,
      iso: null,
      openFilter: false,
      resorts: [],
      resort: null,
      checkInSelected: null,
      checkOutSelected: null,
      checkInList: [],
      checkOutList: [],
    };
  },
  mounted() {
    this.checkFiltersCache();
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    });
    this.$bus.on("open-filters", () => {
      this.showOpenFilters();
    });

    this.listWeeksResorts();
    this.checkMonths();
  },
  methods: {
    async checkMonths() {
      if (this.country && this.city) {
        try {
          const { data } = await this.$axios.get(
            `/week/check-months?country=${this.country}&city=${this.city}`
          );
          this.checkInList = data.checkIn;
          this.checkOutList = data.checkOut;
          // console.log(this.countries);
        } catch (error) {
          console.log(error);
        }
      }
    },
    filterType() {
      setSearchWeekObject({
        country: this.country,
        city: this.city,
        state: this.state,
        checkIn: this.checkInSelected || this.checkIn,
        checkOut: this.checkOutSelected || this.checkOut,
        page: 1,
        limit: 10,
        type: this.type,
        occupancy: this.occupancy,
        capacity: this.capacity,
        kitchen: this.kitchen,
        resort: this.resort,
      });
      this.$bus.emit("change-filter");

      this.listWeeksResorts();

      // this.$router.push(
      //   `/resortlist?country=${this.country}&city=${this.city}&checkIn=${this.checkIn}
      //   &checkOut=${this.checkOut}&page=1&limit=10&token=${this.token}&type=${this.type}`
      // );
      // this.$router.go(0);
    },
    formatDate(date) {
      return this.$moment(date).format("LL");
    },
    resetFilters() {
      this.type = null;
      this.occupancy = null;
      this.capacity = null;
      this.kitchen = null;
      this.resort = null;

      setSearchWeekObject({
        country: this.country,
        city: this.city,
        state: this.state,
        checkIn: this.checkIn,
        page: 1,
        limit: 10,
        checkOut: this.checkOut,
        type: this.type,
        occupancy: this.occupancy,
        capacity: this.capacity,
        kitchen: this.kitchen,
        resort: this.resort,
      });
      this.$bus.emit("change-filter");
    },
    checkFiltersCache() {
      const filters = getSearchWeekObject();
      if (filters.type) this.type = filters.type;
      if (filters.occupancy) this.occupancy = filters.occupancy;
      if (filters.capacity) this.capacity = filters.capacity;
      if (filters.kitchen) this.kitchen = filters.kitchen;
      if (filters.resort) this.resort = filters.resort;
    },
    showOpenFilters() {
      this.openFilter = !this.openFilter;
    },

    async listWeeksResorts() {
      if (this.country && this.city && this.checkIn && this.checkOut) {
        try {
          let payload = {
            country: this.country,
            city: this.city,
            dateIn: this.checkIn,
            dateOut: this.checkOut,
          };
          if (this.state) payload.state = this.state;

          /**
           * Filtros
           */
          if (this.weekType) payload.InvTypeInDetail = this.weekType;
          if (this.occupancy) payload.MaxOccupancy = this.occupancy;
          if (this.capacity) payload.UnitType = this.capacity;
          if (this.kitchen) payload.KitchenType = this.kitchen;
          // if (this.resort) payload.ResortName = this.resort;

          const { data } = await this.$axios.post(`/week/list-resorts`, {
            ...payload,
          });
          this.resorts = data.weeks.sort((a, b) =>
            a > b ? 1 : b > a ? -1 : 0
          );

          // this.loading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>
