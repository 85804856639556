<template>
  <div>
    <div>
      <!-- navbar--active-submenu -->
      <nav
        class="navbar c-header u-zindex--256"
        role="navigation"
        id="generic-MS-nav"
        v-if="true"
      >
        <!-- v-if="club.MicrositeTC === VUE_APP_MICROSITE_TC_DEFAULT" -->
        <div
          class="c-header-collective__bar clr--white"
          :style="`background-color: #${club.ColorSecundarioHex}; color: #${club.ColorSecundarioTextoHex}`"
        >
          <div class="o-container o-container--large">
            <div
              class="o-flexbox o-flexbox--generic-row u-align-items--center u-font-size--small u-justify-content--end"
            >
              <div
                class="c-header__agency-data o-group--default u-display--none u-display--flex@md"
              >
                <div>
                  <b>{{ club.Nombre }}</b>
                </div>
                <div class="o-group--tiny" v-if="club.Telefono">
                  <i class="fa-regular fa-phone"></i
                  ><span>{{ club.Telefono }}</span>
                </div>
                <div class="o-group--tiny" v-if="club.TelefonoWs">
                  <i class="fab fa-whatsapp"></i
                  ><span>{{ club.TelefonoWs }}</span>
                </div>
                <div class="o-group--tiny u-text-transform--lowercase">
                  <i class="fa-regular fa-envelope"></i
                  ><a :href="`mailto:${club.Email}`"> {{ club.Email }}</a>
                </div>
                <div class=""></div>
              </div>
            </div>
          </div>
        </div>
        <div class="container o-container o-container--large">
          <div class="navbar-header">
            <button
              class="navbar-toggle collapsed c-button--userheader"
              data-toggle="collapse"
              type="button"
            >
              <!-- <span class="sr-only"></span><span class="icon-bar top-bar"></span
              ><span class="icon-bar middle-bar"></span
              ><span class="icon-bar bottom-bar"></span> --></button
            ><a class="ms-brand" @click="goHome" style="cursor: pointer">
              <img class="img-brand" alt="logo" :src="club.Logo"
            /></a>
            <div
              class="c-header-submenu__devices-button o-box--small--horizontal o-box--tiny--vertical u-cursor--pointer mr-auto"
              @click="openHiddenAside('submenu')"
            >
              <i
                class="fa-regular fa-bars u-font-size--big"
                v-if="!openMenuMobile"
              ></i>
              <i class="fa-regular fa-times u-font-size--big" v-else></i>
            </div>
          </div>
          <div
            id="collapsableHeader"
            :class="`navbar-collapse collapse ${
              (openMenuMobile && 'in') || ''
            }`"
          >
            <div class="navbar-display">
              <ul class="nav navbar-nav navbar-right flip userheader">
                <li class="">
                  <a
                    @click="goTc"
                    style="cursor: pointer"
                    v-if="club.ModuloTC"
                    >{{ $func.parseIso("hotelsFlightsMore") }}</a
                  >
                </li>
                <li class="btn-menu-help" style="display: none">
                  <a href="#" class="tc-bt-showhelp fakeLink"> Ayúdame </a>
                </li>
                <li
                  id="dropdown_languages"
                  class="dropdown dropdown-languages"
                  data-selected-language="es"
                >
                  <a
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    @click="changeIso"
                    style="cursor: pointer"
                    ><span
                      ><span
                        :class="`flag-icon flag-icon--language ${
                          iso === 'en' ? 'flag-icon-us' : 'flag-icon-es fa'
                        }`"
                      ></span>
                      {{
                        iso === "es"
                          ? $func.parseIso("spanish")
                          : $func.parseIso("english")
                      }}</span
                    >
                    <i class="fa-regular fa-angle-down"></i>
                  </a>
                </li>
                <li
                  :class="`dropdown login header__login ${
                    openMenu ? 'open' : ''
                  }`"
                >
                  <a
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    @click="openMenu = !openMenu"
                    style="cursor: pointer"
                    ><span
                      ><i class="fa-regular fa-user-check user-icon"></i>
                      {{ member.Nombre }} </span
                    ><i
                      v-if="!openMenuMobile"
                      class="fa-regular fa-angle-down"
                    ></i>
                  </a>
                  <ul
                    role="menu"
                    class="dropdown-menu attach-body dropdown-nav-menu dropdown-nav-user"
                  >
                    <!-- <li role="separator" class="divider"></li>
                    <li role="separator" class="divider"></li>
                    <li role="separator" class="divider"></li> -->
                    <li class="logout">
                      <a
                        class="u-text-align--right flip logout-button"
                        @click="logout"
                        style="cursor: pointer"
                        ><i class="fa-regular fa-sign-in-alt" style="margin-right: 10px;"></i>
                        {{ $func.parseIso("logout") }}
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="bkgbase-not btn-feedback">
                  <a
                    style="cursor: pointer"
                    id="improvement-modal"
                    class="ui-commandlink ui-widget"
                    aria-label=""
                  >
                    <b v-if="openMenuMobile" @click="logout"
                      ><i class="fa-regular fa-sign-out-alt"></i>
                      {{ $func.parseIso("logout") }}
                    </b>
                    <b v-else
                      ><i class="fa-regular fa-comment"></i>
                      {{ $func.parseIso("commentsSupport") }}
                    </b>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="c-hidden-aside--submenu"
            class="c-hidden-aside c-hidden-aside--submenu modifier"
          >
            <div class="c-header-submenu__devices-close u-text-align--right">
              <i
                class="fa-regular fa-close o-box--middle u-font-size--middle clr--middle-gray u-cursor--pointer"
                role="button"
                tabindex="0"
              ></i>
            </div>
            <div class="c-header-submenu u-zindex--32" style="display: none">
              <ul
                class="c-header-submenu__list o-container o-container--large o-container--fit@max-lg mb-0 p-0"
              >
                <!-- <li class="c-header-submenu__element u-border--none">
                  <a
                    style="cursor: pointer"
                    class="c-header-submenu__link u-transition dev-PERSONALIZED"
                    ><i class="fa-regular fa-chalkboard-user mr-5"></i
                    ><span>{{ $func.parseIso("myProfile") }}</span>
                  </a>
                </li> -->
                <li class="c-header-submenu__element u-border--none">
                  <a
                    v-if="club.ModuloTC"
                    @click="goTc"
                    style="cursor: pointer"
                    class="c-header-submenu__link u-transition dev-PERSONALIZED"
                    ><i class="fa-regular fa-umbrella-beach mr-5"></i
                    ><span>{{ $func.parseIso("hotelsFlightsMore") }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="c-hidden-aside--submenu__bg"
            class="c-hidden-aside__bg"
            onclick="closeHiddenAside('submenu')"
          ></div>
        </div>
      </nav>
      <div class="c-header-collective" v-else>
        <div
          class="c-header-collective__bar clr--white"
          :style="`background-color: #${club.ColorSecundarioHex}; color: #${club.ColorSecundarioTextoHex}`"
        >
          <div class="o-container o-container--large">
            <div
              class="o-flexbox o-flexbox--generic-row u-align-items--center u-font-size--small u-justify-content--end"
            >
              <div
                class="c-header__agency-data o-group--default u-display--none u-display--flex@md"
              >
                <div>
                  <b>{{ club.Nombre }}</b>
                </div>
                <div class="o-group--tiny" v-if="club.Telefono">
                  <i class="fa-regular fa-phone"></i
                  ><span>{{ club.Telefono }}</span>
                </div>
                <div class="o-group--tiny" v-if="club.TelefonoWs">
                  <i class="fab fa-whatsapp"></i
                  ><span>{{ club.TelefonoWs }}</span>
                </div>
                <div class="o-group--tiny u-text-transform--lowercase">
                  <i class="fa-regular fa-envelope"></i
                  ><a :href="`mailto:${club.Email}`"> {{ club.Email }}</a>
                </div>
                <div class=""></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="c-header-collective__main bg-clr--white c-header-collective--active-submenu u-position--relative u-zindex--32"
        >
          <div class="o-container o-container--large pr-0 pr-20@md">
            <div
              class="c-header-collective__display o-flexbox o-flexbox--generic-row"
            >
              <div
                class="c-header-submenu__devices-button o-box--small--horizontal o-box--tiny--vertical u-cursor--pointer mr-auto"
              >
                <i class="fa-regular fa-bars u-font-size--big"></i>
              </div>
              <div
                id="j_id_31_6_1n:collective-img__container"
                class="c-collective-brand c-collective-brand--header o-section--small"
              >
                <a
                  class="c-collective-brand__link"
                  @click="goHome"
                  style="cursor: pointer"
                >
                  <img class="c-collective-brand__img" :src="club.Logo"
                /></a>
              </div>
              <div
                class="c-button c-button--square u-display--none@md u-border--none"
              >
                <i class="fa-regular fa-bars"></i>
              </div>
              <div
                id="c-hidden-aside--header-collective"
                class="c-hidden-aside c-hidden-aside@md modifier"
              >
                <div class="c-hidden-aside__scroller">
                  <div class="u-display--none@md u-text-align--right">
                    <a
                      class="c-button c-button--square u-bdr-clr--transparent"
                      onclick="closeHiddenAside('header-collective')"
                      ><i class="fa-regular fa-times"></i
                    ></a>
                  </div>
                  <div
                    class="c-header__agency-data o-block o-block--small o-box bg-clr--lighter-gray u-display--none@md mt-20"
                  >
                    <div
                      class="o-block__item u-display--flex u-align-items--center c-header-collective__aside-item"
                    >
                      <b>Kovay Vacations</b>
                    </div>
                    <div
                      class="o-group--tiny o-block__item u-display--flex u-align-items--center c-header-collective__aside-item"
                    >
                      <i class="fa-regular fa-phone"></i
                      ><span>+18772123834</span>
                    </div>
                    <div
                      class="o-group--tiny u-text-transform--lowercase o-block__item u-display--flex u-align-items--center c-header-collective__aside-item"
                    >
                      <i class="fa-regular fa-envelope"></i
                      ><a href="mailto:reservations@vacancyrewards.com">
                        reservations@vacancyrewards.com</a
                      >
                    </div>
                    <div
                      class="o-block__item u-display--flex u-align-items--center c-header-collective__aside-item"
                    ></div>
                  </div>
                  <ul
                    class="c-header-collective__user-header u-display--flex@md"
                  >
                    <li class="u-display--none"></li>
                    <li class="btn-menu-help" style="visibility: hidden">
                      <a
                        href="#"
                        class="tc-bt-showhelp fakeLink"
                        onclick="if($('#c-hidden-aside--header-collective')){ closeHiddenAside('header-collective') } $('#collapsableHeader').collapse('hide');"
                      >
                        Ayúdame
                      </a>
                    </li>
                    <li
                      id="dropdown_languages"
                      class="dropdown dropdown-languages"
                      data-selected-language="es"
                    >
                      <a
                        class="dropdown-toggle"
                        data-toggle="dropdown"
                        @click="changeIso"
                        style="cursor: pointer"
                        ><span
                          ><span
                            :class="`flag-icon flag-icon--language ${
                              iso === 'en' ? 'flag-icon-us' : 'flag-icon-es fa'
                            }`"
                          ></span>
                          {{
                            iso === "es"
                              ? $func.parseIso("spanish")
                              : $func.parseIso("english")
                          }}</span
                        >
                        <i class="fa-regular fa-angle-down"></i>
                      </a>
                    </li>
                    <li
                      :class="`dropdown login header__login ${
                        openMenu ? 'open' : ''
                      }`"
                    >
                      <a
                        class="dropdown-toggle"
                        data-toggle="dropdown"
                        @click="openMenu = !openMenu"
                        style="cursor: pointer"
                        ><span
                          ><i class="fa-regular fa-user-check user-icon"></i>
                          {{ member.Nombre }} </span
                        ><i class="fa-regular fa-angle-down"></i
                      ></a>
                      <ul
                        role="menu"
                        class="dropdown-menu attach-body dropdown-nav-menu dropdown-nav-user"
                      >
                        <!-- <li role="separator" class="divider"></li>
                        <li role="separator" class="divider"></li>
                        <li role="separator" class="divider"></li> -->
                        <li class="logout">
                          <a
                            class="u-text-align--right flip logout-button"
                            @click="logout"
                            style="cursor: pointer"
                            ><i class="fa-regular fa-sign-in-alt" style="margin-right: 10px;"></i>
                            {{ $func.parseIso("logout") }}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="bkgbase-not btn-feedback">
                      <a
                        id="improvement-modal"
                        class="ui-commandlink ui-widget"
                        aria-label=""
                        style="cursor: pointer"
                      >
                        <b
                          ><i class="fa-regular fa-comment"></i>
                          {{ $func.parseIso("commentsSupport") }}
                        </b></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              id="c-hidden-aside--submenu"
              class="c-hidden-aside c-hidden-aside--submenu modifier"
            >
              <div class="c-header-submenu__devices-close u-text-align--right">
                <i
                  class="fa-regular fa-close o-box--middle u-font-size--middle clr--middle-gray u-cursor--pointer"
                  onclick="closeHiddenAside('submenu')"
                  role="button"
                  tabindex="0"
                ></i>
              </div>
              <div class="c-header-submenu u-zindex--32">
                <ul
                  class="c-header-submenu__list o-container o-container--large o-container--fit@max-lg mb-0 p-0"
                >
                  <li class="c-header-submenu__element u-border--none">
                    <a
                      v-if="club.ModuloTC"
                      @click="goTc"
                      style="cursor: pointer"
                      class="c-header-submenu__link u-transition dev-PERSONALIZED"
                      ><i class="fa-regular fa-umbrella-beach mr-5"></i
                      ><span>{{ $func.parseIso("hotelsFlightsMore") }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const {
  VUE_APP_MICROSITE_TC_DEFAULT,
  VUE_APP_URL_LOGIN_DEFAULT,
  VUE_APP_URL_WEEKS_DEFAULT,
} = process.env;
import { getClubObject, getTokenTC, getMemberObject } from "../utils/sesions";
export default {
  data() {
    return {
      iso: null,
      VUE_APP_MICROSITE_TC_DEFAULT,
      sesion: null,
      token: null,
      openMenu: false,
      openMenuMobile: false,
      club: {
        Nombre: "",
        Telefono: "",
        TelefonoWs: "",
        ColorPrimarioHex: "",
        ColorSecundarioHex: "",
        ColorSecundarioTextoHex: "",
        ColorFooterPredeterminado: "",
        Email: "",
        Direccion: "",
        MicrositeTC: VUE_APP_MICROSITE_TC_DEFAULT,
        // UrlTC: VUE_APP_URL_WEEKS_DEFAULT
      },
      member: {
        Nombre: "",
      },
    };
  },
  created() {
    const iso = localStorage.getItem("iso");
    this.iso = iso || "en";

    // this.$bus.on("sesion", ({ sesion, data }) => {
    //   console.log("data sesion", data);
    //   if (data.club) this.club = data.club;
    //   this.member = data.member;
    //   this.sesion = sesion;
    // });

    // this.checkParams();

    this.$bus.on("change-data", () => {
      this.checkParams();
    });
  },
  mounted() {
    this.checkParams();
  },
  methods: {
    changeIso() {
      if (this.iso === "es") {
        localStorage.setItem("iso", "en");
        this.iso = "en";
      } else {
        localStorage.setItem("iso", "es");
        this.iso = "es";
      }
      this.$bus.emit("change-iso");
    },
    goTc() {
      // console.log(this.sesion);
      if (this.club && this.club.UrlTC) {
        window.location.href = `https://${
          this.club.UrlTC
        }?submit=true&keepurl=true${
          this.token ? `&token=${this.token}` : ""
        }&lang=${this.iso}`;
      } else {
        window.location.href = `https://${
          process.env.VUE_APP_URL_TC_DEFAULT
        }?submit=true&keepurl=true${
          this.token ? `&token=${this.token}` : ""
        }&lang=${this.iso}`;
      }
    },
    goHome() {
      this.$router.push("/");
    },
    checkParams() {
      console.log("2. checkParams");

      const token = getTokenTC();
      if (token) this.token = token;

      const club = getClubObject();
      if (club) this.club = club;

      const member = getMemberObject();
      if (member) this.member = member;

      console.log("this.token", this.token);
      console.log("this.club", this.club);
      console.log("this.member", this.member);
    },
    logout() {
      window.location.href = `https://${VUE_APP_URL_LOGIN_DEFAULT}?token=${this.token}`;
    },
    openHiddenAside() {
      this.openMenuMobile = !this.openMenuMobile;
    },
  },
};
</script>
